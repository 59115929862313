import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from '@rails/activestorage'
import Rails from '@rails/ujs'
import $ from 'jquery'

const TEXT_BOX_PLACEHOLDER = 'Text Box'
const BARCODE_PLACEHOLDER = '{member:number}'
const QR_CODE_PLACEHOLDER = '{member:status_url}'
const UNSAVED_PROMPT = "There is unsaved work, are you sure you want to leave this page?"

const IMAGE_DPI = 300
const PT_PER_IN = 72
const PX_PER_IN = 96
const PX_TO_PT = PT_PER_IN / PX_PER_IN
const px2pt = px => Math.round(px * PX_TO_PT)

const LINE_HEIGHT = 1.08

const INPUT_TAGS = ['INPUT', 'SELECT', 'TEXTAREA']

const ITEM_SELECTOR = '.print-template-field'
const ACTIVE_CLASS = 'active'

const DEFAULT_TEXT_ALIGN = 'left'
const DEFAULT_FONT_STYLE = ''
const DEFAULT_TEXT_TRANSFORM = 'none'

const DEFAULT_X_OFFSET = 10
const DEFAULT_Y_OFFSET = 5

const FIELD_PREFIX = 'print_template[print_template_fields_attributes][]'

const
    TYPE_TEXT = 'text',
    TYPE_IMAGE = 'image',
    TYPE_BARCODE = 'barcode',
    TYPE_QRCODE = 'qrcode'

export default class extends Controller {
    static targets = [
        "stage", "document",
        "save",
        "fontFamilyDisplay", "fontSize", "color",
        "left", "center", "right", "justify",
        "bold", "italic", "underline",
        "capitalize", "uppercase", "lowercase",
        "control",
        "x", "y", "z", "w", "h", "r", "o"
    ]

    connect () {
        $('#print-template-fields .print-template-field').draggable({
            helper: "clone",
            appendTo: "body",
            cursor: "grab",
            zIndex: 1e5,
            start (event, ui) {
                ui.helper
                    .removeClass("list-group-item-action")
                    .addClass("border shadow-sm")
                    .width($('#print-template-fields').width())
            }
        })
        const self = this
        $(this.stageTarget).droppable({
            drop (event, ui) {
                const shadow = ui.helper[0]

                const stageBounds = self.documentTarget.getBoundingClientRect()
                const shadowBounds = shadow.getBoundingClientRect()

                const scale = self.documentTarget.offsetWidth / Math.round(stageBounds.width)
                const x = px2pt((shadowBounds.x - stageBounds.x) * scale)
                const y = px2pt((shadowBounds.y - stageBounds.y) * scale)
                const width = px2pt(shadowBounds.width)
                const height = px2pt(shadowBounds.height)
                const token = shadow.dataset.token

                self.createText({ x, y, width, height, token })
            }
        })
        $(this.stageTarget).on('hidden.bs.modal', '#editor-properties', event => {
            this.updateDocumentSize(event)
        })

        // set defaults
        this._fontProperties = {
            font_family: this.fontFamilyDisplayTarget.style.fontFamily,
            font_size: this.fontSizeTarget.value,
            color: this.colorTarget.value,
            text_align: DEFAULT_TEXT_ALIGN,
            font_style: DEFAULT_FONT_STYLE,
            text_transform: DEFAULT_TEXT_TRANSFORM,
        }

        this.saved = true
        this._beforeunload = this.warnUnsaved.bind(this)
        window.addEventListener('beforeunload', this._beforeunload)
    }

    disconnect () {
        window.removeEventListener('beforeunload', this._beforeunload)
    }

    createField ({
        type, data, signed_id, x, y, width, height, description
    }) {
        const field = document.createElement('div')
        field.dataset.controller = 'print-template-field'

        const content = document.createElement(type === TYPE_IMAGE ? 'div' : 'textarea')
        content.className = 'content'
        field.appendChild(content)

        if (type === TYPE_IMAGE) {
            this._appendHidden(field, 'content', '')
            content.style.backgroundImage = `url(${data})`
            if (signed_id) {
                this._appendHidden(field, 'image', signed_id)
            }
        } else {
            content.name = `${FIELD_PREFIX}[content]`
            content.value = data
        }

        const attributes = {
            type,
            ...this._defaultGeometry({ x, y, width, height }),
            ...this._fontProperties
        }

        for (const name in attributes) {
            this._appendHidden(field, name, attributes[name])
        }

        this.documentTarget.appendChild(field)
        // defer focus for one event loop to allow controller to connect
        setImmediate(() => {
            this._updateFocus(field)
            this._emitStateEvent(`insert ${description || type}`)
        })

        return field
    }

    _defaultGeometry (attributes) {
        const z = this.documentTarget.children.length
        const x = attributes.x === undefined ? z * DEFAULT_X_OFFSET : attributes.x
        const y = attributes.y === undefined ? z * DEFAULT_Y_OFFSET : attributes.y
        const width = attributes.width || PT_PER_IN
        const height = attributes.height || Math.ceil(this._fontProperties.font_size * LINE_HEIGHT)
        return { x, y, z, width, height, rotation: 0, opacity: 100 }
    }

    _appendHidden (parent, name, value) {
        const hidden = document.createElement('input')
        hidden.type = "hidden"
        hidden.name = `${FIELD_PREFIX}[${name}]`
        hidden.value = value
        parent.appendChild(hidden)
    }

    addText (event) {
        const button = event.target.closest(ITEM_SELECTOR)
        if (button) {
            const { token } = button.dataset
            // if the attempt to insert a token fails, create a new field
            if (!this._insertToken(token)) {
                const buttonBounds = button.getBoundingClientRect()
                const width = px2pt(buttonBounds.width)
                const height = px2pt(buttonBounds.height)
                this.createText({ width, height, token })
            }
        } else {
            this.createText({})
        }
    }

    _insertToken (token) {
        return this.selection &&
            document.execCommand('insertText', false, token)
    }

    createText ({ x, y, width, height, token }) {
        this.createField({
            type: TYPE_TEXT,
            data: token || TEXT_BOX_PLACEHOLDER,
            x, y, width, height,
            description: `${token || 'text'} field`
        })
    }

    addImage (event) {
        const input = event.target
        const url = input.dataset.directUploadUrl
        if (input.files) {
            for (const file of input.files) {
                this.attachImage(file, url)
            }
            input.value = null
        }
    }

    attachImage (file, url) {
        const image = new Image
        const reader = new FileReader
        const upload = new DirectUpload(file, url)

        let data, signed_id, width, height

        const onUpload = (error, blob) => {
            if (error) {
                console.error('direct-upload', error)
                $.gritter.add({
                    title: 'Upload failed',
                    text: `Could not save ${file.name}, please try again later or use another image`,
                    class_name: 'color danger'
                })
            } else {
                signed_id = blob.signed_id
                this.createField({
                    type: TYPE_IMAGE,
                    data, signed_id, width, height
                })
            }
        }

        const onImage = () => {
            width = image.naturalWidth * PT_PER_IN / IMAGE_DPI
            height = image.naturalHeight * PT_PER_IN / IMAGE_DPI
            upload.create(onUpload)
        }

        reader.onload = () => {
            image.src = data = reader.result
            image.onload = onImage
        }
        reader.readAsDataURL(file)
    }

    addBarcode (event) {
        this.createField({
            type: TYPE_BARCODE,
            data: BARCODE_PLACEHOLDER
        })
    }

    addQR (event) {
        this.createField({
            type: TYPE_QRCODE,
            data: QR_CODE_PLACEHOLDER,
            width: PT_PER_IN, height: PT_PER_IN,
            description: `QR code`
        })
    }

    _getProp (name) {
        return this.element.elements[`print_template[${name}]`].value
    }

    updateDocumentSize (event) {
        console.log("print-template-editor#updateDocumentSize")

        const landscape = this._getProp('landscape') === 'true'
        const page_width = +this._getProp(landscape ? 'page_height' : 'page_width')
        const page_height = +this._getProp(landscape ? 'page_width' : 'page_height')
        const margin_top = +this._getProp('margin_top')
        const margin_left = +this._getProp('margin_left')
        const margin_bottom = +this._getProp('margin_bottom')
        const margin_right = +this._getProp('margin_right')
        const columns = +this._getProp('columns')
        const rows = +this._getProp('rows')
        const column_gap = +this._getProp('column_gap')
        const row_gap = +this._getProp('row_gap')

        const cell_width = (page_width - margin_left - margin_right - column_gap * (columns - 1)) / columns
        const cell_height = (page_height - margin_top - margin_bottom - row_gap * (rows - 1)) / rows
        this.documentTarget.style.width = `${cell_width}pt`
        this.documentTarget.style.height = `${cell_height}pt`

        this._emitStateEvent('edit document properties')
    }

    handleKey (event) {
        console.log("print-template-editor#handleKey", event.key)
        const modified = event.ctrlKey || event.metaKey
        // handle keypress in input field
        if (INPUT_TAGS.includes(event.target.tagName)) {
            // noinspection FallThroughInSwitchStatementJS
            switch (event.key) {
                case 'Enter':
                    // allow enter when holding any modifier key
                    if (modified || event.shiftKey) return
                case 'Escape':
                    event.target.blur()
            }
            return
        } else if (modified) {
            switch (event.key) {
                case 'c':
                    if (this.selection) {
                        this.duplicate()
                        break
                    }
                    return
                case 'b':
                    this._toggle('bold')
                    break
                case 'i':
                    this._toggle('italic')
                    break
                case 'u':
                    this._toggle('underline')
                    break
                case '\\':
                    this._set('font_style', DEFAULT_FONT_STYLE)
                    break
                case 's':
                    Rails.fire(this.element, 'submit')
                    break
                case 'ArrowUp':
                    event.shiftKey ? this.bringToFront() : this.bringForward()
                    break
                case 'ArrowDown':
                    event.shiftKey ? this.sendToBack() : this.sendBackward()
                    break
                default:
                    return
            }
        } else if (this.selection) {
            switch (event.key) {
                case 'ArrowLeft':
                    this._set('x', --this.xTarget.value)
                    break
                case 'ArrowRight':
                    this._set('x', ++this.xTarget.value)
                    break
                case 'ArrowUp':
                    this._set('y', --this.yTarget.value)
                    break
                case 'ArrowDown':
                    this._set('y', ++this.yTarget.value)
                    break
                case 'Backspace':
                case 'Delete':
                    this.remove()
                    break
                case 'Escape':
                    this._updateFocus()
                    break
                default:
                    return
            }
        } else {
            return
        }
        event.preventDefault()
    }

    _updateFocus (element) {
        if (this.selection === element) return
        if (this.selection) {
            this.selection.classList.remove(ACTIVE_CLASS)
        }
        this.selection = element
        if (this.selection) {
            this.selection.classList.add(ACTIVE_CLASS)
        }
        this._updateToolbar()
    }

    _updateToolbar () {
        if (this.selection) {
            const values = this.selection.controller.getAll()
            const type = values.type
            for (const name in values) {
                if (name === 'font_family' && type !== TYPE_TEXT) continue
                this._update(name, values[name])
            }
            this._updateFieldControls(true)
        } else {
            this._updateFieldControls(false)
        }
    }

    _update (name, value) {
        if (value === undefined) return
        // universal properties
        switch (name) {
            case 'id':
            case 'type':
            case 'image':
                return
            case 'x':
                this.xTarget.value = value
                return
            case 'y':
                this.yTarget.value = value
                return
            case 'z':
                this.zTarget.value = value
                return
            case 'width':
                this.wTarget.value = value
                return
            case 'height':
                this.hTarget.value = value
                return
            case 'rotation':
                this.rTarget.value = value
                return
            case 'opacity':
                this.oTarget.value = value
                return
        }
        // font properties
        switch (name) {
            case 'font_family':
                this.fontFamilyDisplayTarget.textContent = value
                this.fontFamilyDisplayTarget.style.fontFamily = value
                break
            case 'font_size':
                this.fontSizeTarget.value = value
                break
            case 'color':
                this.colorTarget.value = value
                break
            case 'text_align':
                this.leftTarget.classList.toggle(ACTIVE_CLASS, value === 'left')
                this.centerTarget.classList.toggle(ACTIVE_CLASS, value === 'center')
                this.rightTarget.classList.toggle(ACTIVE_CLASS, value === 'right')
                this.justifyTarget.classList.toggle(ACTIVE_CLASS, value === 'justify')
                break
            case 'font_style':
                this.boldTarget.classList.toggle(ACTIVE_CLASS, value.includes('bold'))
                this.italicTarget.classList.toggle(ACTIVE_CLASS, value.includes('italic'))
                this.underlineTarget.classList.toggle(ACTIVE_CLASS, value.includes('underline'))
                break
            case 'text_transform':
                this.capitalizeTarget.classList.toggle(ACTIVE_CLASS, value === 'capitalize')
                this.uppercaseTarget.classList.toggle(ACTIVE_CLASS, value === 'uppercase')
                this.lowercaseTarget.classList.toggle(ACTIVE_CLASS, value === 'lowercase')
                break
            default:
                console.log('print-template-editor', 'unknown property', name, value)
                return
        }
        this._fontProperties[name] = value
    }

    _updateFieldControls (active) {
        for (const target of this.controlTargets) {
            target.classList.toggle('disabled', !active)
        }
        if (!active) {
            this.xTarget.value = this.yTarget.value = this.zTarget.value =
                this.wTarget.value = this.hTarget.value = this.rTarget.value = this.oTarget.value = ''
        }
    }

    updatePosition (event) {
        this.xTarget.value = this.selection.controller.get('x')
        this.yTarget.value = this.selection.controller.get('y')
        this._emitStateEvent(`move`)
    }

    updateSize (event) {
        this.wTarget.value = this.selection.controller.get('width')
        this.hTarget.value = this.selection.controller.get('height')
        this._emitStateEvent(`resize`)
    }

    updateContent (event) {
        this._emitStateEvent(`edit`)
    }

    setFocus (event) {
        const element = event.target.closest(ITEM_SELECTOR)
        this._updateFocus(element)
    }

    restoreFocus (event) {
        setImmediate(() => {
            const element = this.documentTarget.querySelector(`${ITEM_SELECTOR}.${ACTIVE_CLASS}`)
            this._updateFocus(element)
        })
    }

    _set (name, value) {
        if (this.selection) {
            this.selection.controller.set(name, value)
            const friendly_name = name.replace('_', ' ')
            const friendly_value = value.toString().replace('_', ' ')
            const description = `set ${friendly_name} to ${friendly_value}`
            this._emitStateEvent(description)
        }
        this._update(name, value)
    }

    set (event) {
        const property = event.target.closest('[data-property]').dataset.property
        const value = 'value' in event.target ?
            event.target.value : event.target.closest('[data-value]').dataset.value
        this._set(property, value)
    }

    toggle (event) {
        const value = event.target.closest('[data-value]').dataset.value
        this._toggle(value)
    }

    _toggle (value) {
        const styles = this._fontProperties.font_style ? this._fontProperties.font_style.split('_') : []
        const index = styles.indexOf(value)
        if (index < 0) {
            styles.push(value)
        } else {
            styles.splice(index, 1)
        }
        this._set('font_style', styles.join('_'))
    }

    rotate (event) {
        if (this.selection) {
            const delta = +event.target.closest('[data-value]').dataset.value
            let value = +this.selection.controller.get('rotation')
            value = (value + delta + 360) % 360
            this._set('rotation', value)
        }
    }

    remove () {
        if (this.selection) {
            this.selection.remove()
            this._updateFocus()
            this._emitStateEvent(`remove field`)
        }
    }

    duplicate () {
        if (this.selection) {
            const clone = this.selection.cloneNode(true)
            const id = clone.querySelector(`[name$="[id]"]`)
            if (id) id.remove()
            const x = +this.selection.controller.get('x') + DEFAULT_X_OFFSET
            const y = +this.selection.controller.get('y') + DEFAULT_Y_OFFSET
            const z = this.documentTarget.children.length
            this.documentTarget.appendChild(clone)
            setImmediate(() => {
                clone.controller.setAll({ x, y, z })
                this._updateFocus(clone)
                this._emitStateEvent(`duplicate field`)
            })
        }
    }

    setZ (event) {
        if (this._setZ(+event.target.value)) {
            this._emitStateEvent(`set z to ${z}`)
        }
    }

    sendToBack () {
        if (this._setZ(0)) {
            this._emitStateEvent(`send to back`)
        }
    }

    bringToFront () {
        if (this._setZ(this.documentTarget.children.length - 1)) {
            this._emitStateEvent(`bring to front`)
        }
    }

    sendBackward () {
        if (this._setZRelative(-1)) {
            this._emitStateEvent(`send backward`)
        }
    }

    bringForward () {
        if (this._setZRelative(1)) {
            this._emitStateEvent(`bring forward`)
        }
    }

    _setZRelative (delta) {
        if (!this.selection) return false
        const z = +this.selection.controller.get('z') + delta
        return this._setZ(z)
    }

    _setZ (z) {
        if (!this.selection) return false
        if (z < 0 || z > this.documentTarget.children.length - 1) return false
        const current_z = +this.selection.controller.get('z')
        if (current_z === z) return false
        const direction = current_z < z ? 1 : -1
        for (const child of this.documentTarget.children) {
            if (child === this.selection) continue
            const current_z = +child.controller.get('z')
            if (current_z >= z) {
                child.controller.set('z', current_z - direction)
            }
        }
        this.selection.controller.set('z', z)
        this._update('z', z)
        return true
    }

    _emitStateEvent (description) {
        const event = new CustomEvent('state', {
            bubbles: true,
            detail: {description}
        })
        this.documentTarget.dispatchEvent(event)
    }

    markSaved () {
        this.saved = true
        this.saveTarget.classList.add('disabled')
    }

    markUnsaved () {
        this.saved = false
        this.saveTarget.classList.remove('disabled')
    }

    warnUnsaved (event) {
        // handles both turbolinks:before-visit and native beforeunload
        if (!this.saved && (event.isTrusted || !confirm(UNSAVED_PROMPT))) {
            event.preventDefault()
            return event.returnValue = UNSAVED_PROMPT
        }
    }

    saveFailed (event) {
        const [data, status, xhr] = event.detail
        $.gritter.add({
            title: status,
            text: `Failed to save ${this._getProp('name')}`,
            class_name: 'color danger'
        })
    }
}
