import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    map

    connect() {
        const markers = JSON.parse(this.element.dataset.markers)
        console.log("map#connect", markers)

        const lats = markers.map(e => e[1])
        const longs = markers.map(e => e[2])
        const centerLat = (Math.min(...lats) + Math.max(...lats)) / 2
        const centerLong = (Math.min(...longs) + Math.max(...longs)) / 2

        const zoom = +this.element.dataset.zoom || 8

        this.map = new google.maps.Map(this.element, {
            zoom,
            center: new google.maps.LatLng(centerLat, centerLong),
            mapTypeId: google.maps.MapTypeId.ROADMAP
        })

        for (const marker of markers) {
            const [ title, lat, long ] = marker
            new google.maps.Marker({
                position: new google.maps.LatLng(lat, long),
                map: this.map,
                title
            })
        }
    }
}
