import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'

const OFFSET = 220
const DURATION = 500

export default class extends Controller {

    connect () {
        this.button = $(this.element)
    }

    update () {
        if (document.scrollingElement.scrollTop > OFFSET) {
            this.button.fadeIn(DURATION)
        } else {
            this.button.fadeOut(DURATION)
        }
    }

    scroll () {
        $('html, body').animate({scrollTop: 0}, DURATION)
    }

}
