export default class MoriDataTableOptions {
  build(options) {
    const dtOptions = {}
    this._dataTablesDom(dtOptions, options)
    if (!options.noFooter) {
      this._dataTablesButtons(dtOptions, options)
    }
    this._dataTablesLengthMenu(dtOptions, options)
    this._dataTablesInfoCallback(dtOptions, options)
    this._dataTablesSaveState(dtOptions, options)
    this._dataTablesColumnDefs(dtOptions, options)
    return dtOptions
  }

  _dataTablesDom(dtOptions, options) {
    dtOptions.dom = "<'row be-datatable-header'<'col'<'d-inline-block'><'d-inline-block'l>><'col fit-content'f>>" +
                     "<'be-datatable-body't>"
    if (options.noFooter) {
      dtOptions.dom +=
          "<'row p-2'<'col'i><'col fit-content'p>>"
    } else {
      dtOptions.dom +=
          "<'row be-datatable-footer'<'col'i><'col fit-content'p>>" +
          "<'be-datatable-footer be-datatable-footer-exports text-center'B>"
    }
  }

  _dataTablesButtons(dtOptions, options) {
    dtOptions.buttons = [
      this._dataTableCopy(options),
      this._dataTableExcel(options),
      this._dataTableCSV(options),
      this._dataTablePDF(options),
      this._dataTablePrint(options)
    ]
  }

  _dataTablesLengthMenu(dtOptions, options) {
    dtOptions.lengthMenu = options.lengthMenu || [[10,20,-1],[10,20,"All"]] // TODO: constantize
  }

  _dataTablesInfoCallback(dtOptions, options) {
    dtOptions.infoCallback = (settings, start, end, max, total, pre) => {
      return start +"-"+ end + " of " + max
    }
  }

  _dataTablesSaveState(dtOptions, options) {
    dtOptions.stateSave = true
    dtOptions.stateSaveParams = function (settings, data) {
      // disable persistence of search term
      data.search.search = ''
    }
    dtOptions.stateSaveCallback = function (settings, data) {
      localStorage.setItem('DataTables_' + settings.sInstance, JSON.stringify(data))
    }
    dtOptions.stateLoadCallback = function (settings) {
      return JSON.parse(localStorage.getItem('DataTables_' + settings.sInstance))
    }
  }

  _dataTablesColumnDefs(dtOptions, options) {
    dtOptions.columnDefs = [
      { targets: 'dt-hide', visible: false },
      { targets: 'dt-no-sort', orderable: false }
    ]
  }

  _dataTableCopy(options) {
    const dtCopyOptions = {
      extend: 'copy',
      footer: true
    }
    this._dataTablesExport(dtCopyOptions, options)
    return dtCopyOptions
  }

  _dataTableCSV(options) {
    const dtCsvOptions = {
      extend: 'csv',
      footer: true
    }
    this._dataTablesFilename(dtCsvOptions, options)
    this._dataTablesExport(dtCsvOptions, options)
    return dtCsvOptions
  }

  _dataTableExcel(options) {
    const dtExcelOptions = {
      extend: 'excel',
      footer: true
    }
    this._dataTablesFilename(dtExcelOptions, options)
    this._dataTablesTitle(dtExcelOptions, options)
    this._dataTablesExport(dtExcelOptions, options)
    return dtExcelOptions
  }

  _dataTablePDF(options) {
    const dtPdfOptions = {
      extend: 'pdf',
      footer: true
    }
    this._dataTablesFilename(dtPdfOptions, options)
    this._dataTablesTitle(dtPdfOptions, options)
    this._dataTablesPdfPageSize(dtPdfOptions, options.pdf)
    this._dataTablesPdfCustomize(dtPdfOptions, options.pdf)
    this._dataTablesExport(dtPdfOptions, options)
    dtPdfOptions.exportOptions.stripNewlines = false
    return dtPdfOptions
  }

  _dataTablePrint(options) {
    const dtPrintOptions = {
      extend: 'print',
      exportOptions: {
        stripHtml: false
      },
    }
    this._dataTablesTitle(dtPrintOptions, options)
    // this._dataTablesExport(dtPrintOptions, options)
    // dtPrintOptions.exportOptions.stripHtml = false
    return dtPrintOptions
  }

  _dataTablesFilename(dtOptions, options) {
    if (options && options.filename) {
      dtOptions.filename = options.filename
    }
  }

  _dataTablesTitle(dtOptions, options) {
    if (options && options.title) {
      dtOptions.title = options.title
    }
  }

  _dataTablesPdfPageSize(dtPdfOptions, pdfOptions) {
    dtPdfOptions.pageSize = pdfOptions && pdfOptions.pageSize || 'LETTER' // TODO: constantize
    dtPdfOptions.orientation = pdfOptions.orientation
  }

  _dataTablesPdfCustomize(dtPdfOptions, pdfOptions) {
    const centeredColumns = pdfOptions && pdfOptions.centeredColumns || []

    const rightAlignedColumns = pdfOptions && pdfOptions.rightAlignedColumns || []

    const columnWidths = pdfOptions && pdfOptions.columnWidths || []

    dtPdfOptions.customize = (doc) => {
      doc.styles.tableHeader.color = '#000000' // TODO: constantize
      doc.styles.tableHeader.fillColor = '#d5e0e7' // TODO: constantize
      if (columnWidths.length === 0) {
        // count the columns in the first row of the table
        const colCount = doc.content[1].table.body[0].length
        const colWidth = Math.floor((100 / colCount) * 100) / 100
        for (let i = 0; i < colCount; i++) {
          columnWidths.push(colWidth + '%')
        }
      }
      doc.content[1].table.widths = columnWidths
      if (centeredColumns.length !== 0) {
        doc.content[1].table.body.forEach((row) => {
          row.forEach((column, index) => {
            if (centeredColumns.includes(index)) {
              column.alignment = 'center' // TODO: constantize
            }
          })
        })
      }
      if (rightAlignedColumns.length !== 0) {
        doc.content[1].table.body.forEach((row) => {
          row.forEach((column, index) => {
            if (rightAlignedColumns.includes(index)) {
              column.alignment = 'right' // TODO: constantize
            }
          })
        })
      }
      doc.footer = (currentPage, pageCount) => {
        const footerText = 'Prepared by Groupable - ' +
            new Date().toLocaleDateString("en-US") +
            ' - page ' + currentPage + ' of ' + pageCount
        return {
          table: {
            widths: ['*'],
            body: [
              [{
                text: footerText,
                alignment: 'center'
              }]
            ]
          },
          layout: 'noBorders'
        }
      }
    }
  }

  _dataTablesExport(dtOptions, options) {
    const columnTypes = options && options.columnTypes || []

    const formatCurrency = value => {
      value = parseFloat(value.replace('$', ''))
      if (value || value === 0) {
        return (value < 0 ? '-$' : '$') + Math.abs(value).toFixed(2)
      }
      return ''
    }

    const exportValue = node => {
      // special case for select elements, return current selection text
      let select = node.querySelector('select')
      if (select) {
        return select.options[select.selectedIndex].text
      }
      // special case for input text elements, return value
      let input = node.querySelector('input[type=text]')
      if (input) {
        return input.value
      }
      // otherwise return the text content
      return node.textContent
    }

    dtOptions.exportOptions = {
      format: {
        body: (data, row, column, node) => {
          let value = exportValue(node).trim().replace(/\n\n+/g, '\n')
          if (columnTypes[column] === 'currency') {
            return formatCurrency(value)
          }
          return value
        }
      }
    }
  }
}
