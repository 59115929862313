import {Controller} from "@hotwired/stimulus";
import {loadConnectAndInitialize} from '@stripe/connect-js';
import Rails from '@rails/ujs'


export default class extends Controller {
    static targets = ["error", "banner", "bannerContainer", "payments", "payouts"]

    connect() {
        console.log("stripe-dashboard-payments#connect")
        const fetchClientSecret = async () => {

            // Fetch the AccountSession client secret
            const response = await fetch(
                `/integrations/stripe/account_sessions?stripe_account_id=${this.element.dataset.stripeAccountId}`,
                {
                    method: "POST",
                    headers: {
                        'X-CSRF-Token': Rails.csrfToken()
                    },
                }
            );

            if (response.ok) {
                const {client_secret} = await response.json();
                return client_secret;
            } else {
                // Handle errors on the client side here
                const {error} = await response.json();
                console.error('An error occurred: ', error);
                if (this.hasErrorTarget) {
                    this.errorTarget.textContent = error
                    this.errorTarget.classList.remove("d-none")
                }
            }
        }

        const stripeConnectInstance = loadConnectAndInitialize({
            // This is a placeholder - it should be replaced with your publishable API key.
            // Sign in to see your own test API key embedded in code samples.
            // Don’t submit any personally identifiable information in requests made with this key.
            publishableKey: this.element.dataset.stripePublishableKey,
            fetchClientSecret: fetchClientSecret,
        });

        if (this.hasPaymentsTarget) {
            const paymentComponent = stripeConnectInstance.create("payments");
            this.paymentsTarget.appendChild(paymentComponent)
        }

        if (this.hasPayoutsTarget) {
            const payoutsComponent = stripeConnectInstance.create("payouts")
            this.payoutsTarget.appendChild(payoutsComponent)
        }

        if (this.hasBannerTarget) {
            const notificationBanner= stripeConnectInstance.create('notification-banner');
            this.bannerTarget.appendChild(notificationBanner)

            if (this.hasBannerContainerTarget) {
                notificationBanner.setOnNotificationsChange(response => {
                    const shouldHide = !(response.actionRequired || response.total)
                    this.bannerContainerTarget.classList.toggle('d-none', shouldHide)
                })
            }
        }
    }

    disconnect() {
        console.log("stripe-dashboard-payments#disconnect")
        if (this.hasPaymentsTarget) {
            this.paymentsTarget.innerHTML = ''
        }

        if (this.hasPayoutsTarget) {
            this.payoutsTarget.innerHTML = ''
        }

        if (this.hasBannerTarget) {
            this.bannerTarget.innerHTML = ''
        }

    }


}
