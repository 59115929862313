import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'

export default class extends Controller {
    connect () {
        console.log("sparkline#connect", this.element)
        this.originalHtml = this.element.innerHTML
        this.render()
    }

    disconnect () {
        console.log("sparkline#disconnect", this.element)
        this.element.classList.remove('show')
        this.element.innerHTML = this.originalHtml
    }

    render () {
        const values = this.originalHtml.split(',')
            .map(n => Number.parseFloat(n))
        const max = Math.max(...values)

        const bars = values.map(n => {
            const percent = n * 100 / max
            const bar = document.createElement('div')
            const fill = document.createElement('div')
            bar.appendChild(fill)
            fill.style.height = `${percent}%`
            bar.title = `${n}`
            $(bar).tooltip()
            return bar
        })

        this.element.innerHTML = ''
        this.element.append(...bars)
        this.element.classList.add('show')
    }
}
