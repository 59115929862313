import { Controller } from "@hotwired/stimulus"
import MoriDataTableOptions from '../mori/data_table_options'
import $ from 'jquery'

import jszip from 'jszip' // For Excel export
import pdfmake from 'pdfmake' // For PDF export
import 'pdfmake/build/vfs_fonts' // PDF fonts

import DataTable from 'datatables.net-bs4'

import 'datatables.net-buttons-bs4'
import 'datatables.net-buttons/js/buttons.html5.mjs'
import 'datatables.net-buttons/js/buttons.print.mjs'
import 'datatables.net-buttons/js/buttons.colVis.mjs'

DataTable.Buttons.jszip(jszip)
DataTable.Buttons.pdfMake(pdfmake)

import 'datatables.net-responsive-bs4'

export default class extends Controller {
    table = null
    dataTable = null

    connect() {
        this.table = this.element.querySelector('table')
        console.log("data-table#connect", this.table)

        const columnCount = this.table.querySelectorAll('thead > tr > th').length
        this.dataTable = new DataTable(this.table, this._buildOptions(this.table))

        if (columnCount > 2) {
            this._buildColumnToggle(this.dataTable)
        }

        this.element.tabIndex = -1
        this.element.addEventListener('keydown', this.navigate.bind(this))
    }

    disconnect() {
        console.log("data-table#disconnect", this.table)
        this.dataTable.destroy()
    }

    navigate (event) {
        // focus must be on the table or pagination buttons
        if (event.target !== this.element && !event.target.classList.contains('page-link')) return
        if (['ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp'].includes(event.key)) {
            const { page, pages } = this.dataTable.page.info()
            if (event.key === 'ArrowLeft' || event.key === 'ArrowUp') {
                if (page > 0) {
                    this.dataTable.page('previous').draw(false)
                }
            } else {
                if (page < pages - 1) {
                    this.dataTable.page('next').draw(false)
                }
            }
            event.preventDefault()
        }
    }

    _buildOptions(element) {
        const table = $(element)

        const { title, filename, orientation, lengthMenu } = element.dataset

        const columns = table.find('thead > tr > th')
        const columnWidths = columns.map((i, th) => th.dataset.printWidth).toArray()
        const columnTypes = columns.map((i, th) => th.dataset.type || '').toArray()
        const centeredColumns = columns.map((i, th) => th.classList.contains('text-center') ? i : undefined).toArray()
        const rightAlignedColumns = columns.map((i, th) => th.classList.contains('text-right') ? i : undefined).toArray()
        const noFooter = 'noFooter' in element.dataset

        const options = { title, filename, pdf: {columnWidths, centeredColumns, rightAlignedColumns, orientation}, columnTypes, noFooter, lengthMenu }
        console.log("data-table options", options)
        return new MoriDataTableOptions().build(options)
    }

    _buildColumnToggle (table) {
        const container = table.table().container().querySelector('.be-datatable-header > .col > div')
        const buttons = new DataTable.Buttons(table, {
            buttons: [
                {
                    extend: 'colvis',
                    columns: ':not(.dt-no-sort, .dt-no-toggle)',
                    text: '<span class="icon mdi mdi-menu"></span>',
                    className: 'btn-big mr-4'
                }
            ]
        })
        buttons.dom.container.appendTo(container)
    }
}
