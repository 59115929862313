import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["input", "placeholder", "preview", "filename"]

    connect () {
        console.log('image-preview#connect')
        document.addEventListener('dragover', this.dragHandler.bind(this))
        document.addEventListener('drop', this.dropHandler.bind(this))
    }

    disconnect () {
        console.log('image-preview#disconnect')
        document.removeEventListener('dragover', this.dragHandler.bind(this))
        document.removeEventListener('drop', this.dropHandler.bind(this))
    }

    render () {
        console.log('image-preview#render')

        const input = this.inputTarget
        const preview = this.previewTarget

        if (input.files && input.files.length === 1) {
            preview.classList.remove('show')
            if (this.hasPlaceholderTarget) {
                this.placeholderTarget.classList.add('show')
            }

            const file = input.files[0]
            if (this.hasFilenameTarget) {
                this.filenameTarget.textContent = file.name
            }

            const reader = new FileReader

            reader.onload = () => {
                preview.src = reader.result

                // noinspection BadExpressionStatementJS
                preview.clientWidth // reflow in order to trigger transition on .show
                preview.classList.add('show')

                if (this.hasPlaceholderTarget) {
                    this.placeholderTarget.classList.remove('show')
                }
            }

            reader.readAsDataURL(file)
        }
    }

    dragHandler (event) {
        event.preventDefault()
    }

    dropHandler (event) {
        console.log('image-preview#drop')
        if (event.dataTransfer.files.length === 1) {
            this.inputTarget.files = event.dataTransfer.files
            this.render()
        }
        event.preventDefault()
    }
}
