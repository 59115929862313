import Turbolinks from 'turbolinks'
Turbolinks.start()

// https://github.com/turbolinks/turbolinks/issues/272
// https://thelucid.com/2019/02/20/handling-get-remote-forms-with-turbolinks-in-rails-6
document.addEventListener('turbolinks:load', function(event) {
    for (let form of document.querySelectorAll('form[method=get][data-remote=true]')) {
        form.addEventListener('ajax:beforeSend', function (event) {
            const options = event.detail[1]
            Turbolinks.visit(options.url)
            event.preventDefault()
        })
    }

    // workaround for <a href='#'> tags that do not call preventDefault()
    // in a perfect world, this would be the library/event handler's responsibility
    // turbolinks makes this commonly neglected duty obvious by doing a reload and breaking history
    for (const a of document.querySelectorAll('a[href="#"]')) {
        a.addEventListener('click', event => event.preventDefault())
    }
})

// handle 401 unauthorized by doing a full page load to the page specified in the response header
// https://github.com/turbolinks/turbolinks/issues/86
document.addEventListener('turbolinks:request-end', event => {
    if (event.data.xhr.status === 401) {
        window.location = event.data.xhr.getResponseHeader("WWW-Authenticate")
    }
})

// close modals before navigation
import $ from 'jquery'
document.addEventListener('turbolinks:visit', () => $('.modal').modal('hide'))
