import {Controller} from "@hotwired/stimulus"
import $ from 'jquery'

export default class extends Controller {
    connect () {
        console.log("unsaved-modal-warning#connect", this)

        const form = this.element
        const modal = this.element.closest('.modal')
        const message = this.element.dataset.unsavedMessage || 'There are unsaved changes, are you sure you want to exit?'
        let unsaved_changes = this.element.dataset.unsaved === 'true'

        $(form).
            change(() => { unsaved_changes = true }).
            submit(() => { unsaved_changes = false })

        this.$modal = $(modal)
        this.$modal.on('hide.bs.modal', e => !unsaved_changes || confirm(message))
    }

    disconnect () {
        console.log("unsaved-modal-warning#disconnect", this)
        this.$modal.off('hide.bs.modal')
    }
}
