import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'

export default class extends Controller {
    show(event) {
        console.log("show-modals#click")

        const confirmation = this.element.dataset.confirm
        if (confirmation) {
            if (!confirm(confirmation)) return
        }

        $("#md-default").load(this.data.get('path'), () => $("#md-default").modal() )
        event.preventDefault()
    }
}
