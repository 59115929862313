import $ from 'jquery'

// expose jQuery to the global scope, for e.g. SJR and inline scripts
window.jQuery = window.$ = $

// add jQuery .beforeOn() to complement .on()
// registers an event handler to run before all others
// assists in building workarounds for third party plugin behaviors
$.fn.beforeOn = function (type, fn) {
    this.each(function () {
        $(this).on(type, fn)
        const bindings = $._data(this, 'events')[type]
        bindings.unshift(bindings.pop())
    })
    return this
}
