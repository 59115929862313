import { Controller } from "@hotwired/stimulus"

const DISPLAY_CLASSES = 'float-right mt-2'
const VALID_COLOR = '#666'
const INVALID_COLOR = 'red'

export default class extends Controller {
    connect() {
        this.limit = parseInt(this.element.dataset.limit || this.element.maxLength, 10)

        this.display = this._buildDisplay()
        this.element.insertAdjacentElement('afterend', this.display)

        this.updater = this.update.bind(this)
        this.element.addEventListener('input', this.updater)
        this.update()
    }

    disconnect () {
        this.display.remove()
        this.element.removeEventListener('input', this.updater)
    }

    update () {
        const count = this.element.value.length
        this.display.textContent = count + "/" + this.limit
        this.display.style.color = count > this.limit ? INVALID_COLOR : VALID_COLOR
    }

    _buildDisplay () {
        const display = document.createElement('div')
        display.className = DISPLAY_CLASSES
        return display
    }
}
