import { Controller } from "@hotwired/stimulus"

const MAX_UNDO_STACK = 100

export default class extends Controller {
    static targets = [
        "undo", "redo", "document"
    ]

    connect () {
        console.log("undo-controller#connect")
        this.history = []
        this.depth = 0
        setImmediate(() => this.snapshot())
    }

    snapshot (event) {
        this._serializeValues()
        const data = this.documentTarget.innerHTML
        if (this.history.length && this.history[this.history.length - this.depth - 1].data === data) {
            console.log('undo-controller', 'no change detected')
            return
        }

        const description = event ? event.detail.description : ''
        console.log("undo-controller#snapshot", description)
        this.history.length -= this.depth
        this.depth = 0
        this.history.push({ description, data })
        if (this.history.length > MAX_UNDO_STACK) this.history.shift()
        this.update()
    }

    restore (delta) {
        this.depth -= delta
        const state = this.history[this.history.length - this.depth - 1]
        this.documentTarget.innerHTML = state.data
        this.documentTarget.dispatchEvent(new Event('restore'))
        this.update()
    }

    update () {
        this.undoTarget.classList.toggle('disabled', !this.canUndo())
        if (this.undoTarget.dataset.toggle === 'tooltip') {
            this.undoTarget.dataset.originalTitle = this.undoDescription()
        } else {
            this.undoTarget.title = this.undoDescription()
        }

        this.redoTarget.classList.toggle('disabled', !this.canRedo())
        if (this.redoTarget.dataset.toggle === 'tooltip') {
            this.redoTarget.dataset.originalTitle = this.redoDescription()
        } else {
            this.redoTarget.title = this.redoDescription()
        }
    }

    canUndo () {
        return this.history.length > this.depth + 1
    }

    canRedo () {
        return this.depth > 0
    }

    undoDescription () {
        if (this.canUndo()) {
            return `Undo ${this.history[this.history.length - this.depth - 1].description}`
        } else {
            return 'Undo'
        }
    }

    redoDescription () {
        if (this.canRedo()) {
            return `Redo ${this.history[this.history.length - this.depth].description}`
        } else {
            return 'Redo'
        }
    }

    undo () {
        console.log("undo-controller#undo")
        if (this.canUndo()) {
            this.restore(-1)
        }
    }

    redo () {
        console.log("undo-controller#redo")
        if (this.canRedo()) {
            this.restore(1)
        }
    }

    shortcuts (event) {
        if (event.ctrlKey || event.metaKey) {
            if (event.key === 'y') {
                this.redo()
                event.preventDefault()
            } else if (event.key === 'z') {
                event.shiftKey ? this.redo() : this.undo()
                event.preventDefault()
            }
        }
    }

    _serializeValues () {
        // see spec for information on what attributes are serialized into the DOM
        // https://html.spec.whatwg.org/multipage/input.html#dom-input-value-value
        const valueElements = this.documentTarget.querySelectorAll('input:not([type=hidden]), select, textarea')
        for (const input of valueElements) {
            input.defaultValue = input.value
        }
    }
}
