import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'

const DEFAULT_HEIGHT = 500

export default class extends Controller {
    connect() {
        console.log("summernote#connect", this.element)
        $(this.element).summernote(this._buildOptions())
    }

    _buildOptions () {
        return {
            minHeight: parseInt(this.element.dataset.height) || DEFAULT_HEIGHT,
            toolbar: [
                ['preset', ['style']],
                ['style', ['bold', 'italic', 'underline', 'strikethrough', 'clear']],
                ['fontname', ['fontname']],
                ['color', ['color']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['table', ['table']],
                // ['insert', ['link', 'picture', 'video']],
                ['view', ['fullscreen', 'codeview', 'help']],
            ],
        }
    }
}
