import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "selector", "button",
        "input", "hint"
    ]

    selected_option = null

    connect () {
        console.log("account-selector#connect", this)
        this.update()
        this.label()
    }

    create (event) {
        const parent_name = this.selected_option.dataset.label
        this._setHint(`Subaccount of ${parent_name}`)
        this.toggle(event)
    }

    update () {
        this.selected_option = this.selectorTarget.options[this.selectorTarget.selectedIndex]
        this.buttonTarget.disabled = this.selected_option.dataset.sub !== 'true'
    }

    toggle (event) {
        this.selectorTarget.parentElement.classList.toggle('d-none') || this._clearHint()
        this.inputTarget.parentElement.classList.toggle('d-none') || this.inputTarget.focus()
        event && event.preventDefault()
    }

    submit () {
        if (this.inputTarget.value) {
            this._post(this.element.dataset.createPath, {
                account: {
                    name: this.inputTarget.value,
                    parent_account_id: this.selectorTarget.value
                }
            }).
            then(response => this._handleResponse(response))
        }
    }

    handleKey (event) {
        // cancel default submission and manually handle save on enter
        if (event.key === 'Enter') {
            this.inputTarget.blur()
            event.preventDefault()
        }
    }

    unlabel () {
        if (this.selected_option) {
            this.selected_option.removeAttribute('label')
        }
    }

    label () {
        if (this.selected_option && this.selected_option.dataset.label) {
            this.selected_option.setAttribute('label', this.selected_option.dataset.label)
        }
    }

    // restore original option text
    unkey () {
        for (const option of this.selectorTarget.options) {
            if (option.dataset.indented) {
                option.text = option.dataset.indented
            }
        }
    }

    // remove indent from options for keyboard accessibility
    key () {
        for (const option of this.selectorTarget.options) {
            if (!option.dataset.indented) {
                option.dataset.indented = option.text
            }
            option.text = option.text.replace(/^-+ /, '')
        }
    }

    _addOption (label, id, data) {
        let index = 0
        for (const option of this.selectorTarget.options) {
            if (option.dataset.sort && option.dataset.sort > data.sort) {
                break
            }
            index++
        }

        const selectors = document.querySelectorAll('select[name*="[account_id]"]')
        for (const selector of selectors) {
            const option = new Option(label, id)
            Object.assign(option.dataset, data)
            selector.add(option, index)
        }

        this.unlabel()
        this.selectorTarget.selectedIndex = index
        this.update()
        this.label()
    }

    _setHint (msg) {
        this.hintTarget.textContent = msg
        this.hintTarget.classList.remove('d-none', 'invalid-feedback')
        this.hintTarget.classList.add('small', 'text-muted', 'pt-1')
    }

    _setInvalid (msg) {
        this.hintTarget.textContent = msg
        this.hintTarget.classList.remove('d-none', 'small', 'text-muted', 'pt-1')
        this.hintTarget.classList.add('invalid-feedback')
        this.element.classList.add("form-group-invalid")
        this.inputTarget.classList.add("is-invalid")
    }

    _clearHint () {
        this.hintTarget.classList.add('d-none')
        this.element.classList.remove("form-group-invalid")
        this.inputTarget.classList.remove("is-invalid")
        this.inputTarget.value = ''
    }

    _handleResponse (response) {
        if (response.error) {
            this._setInvalid(response.error)
        } else {
            this._addOption(response[0], response[1], response[2].data)
            this.toggle()
        }
    }

    _post (url, data) {
        return fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-CSRF-Token': this._csrf_token(),
                'X-Requested-With': 'XMLHttpRequest'
            },
            body: JSON.stringify(data)
        }).
        then(response => response.json())
    }

    _csrf_token () {
        return document.querySelector('meta[name=csrf-token]').content
    }

}
