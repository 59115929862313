import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "content", "submit"
    ]

    connect () {
        console.log("terms-controller#connect")
    }

    update () {
        if (this._distanceToBottom(this.contentTarget) < 10) {
            console.log("terms-controller#update", "bottom")
            this.submitTarget.removeAttribute('disabled')
        }
    }

    _distanceToBottom (element) {
        return element.scrollHeight - element.scrollTop - element.clientHeight
    }
}
