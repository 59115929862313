import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "button", "printable"
    ]

    connect () {
        console.log("print-controller#connect")

        if (this.hasButtonTarget) {
            this.buttonTarget.addEventListener('click', this.print.bind(this))
        }
    }

    print () {
        if (this.element.dataset.path) {
            this.print_url()
        } else if (this.hasPrintableTarget) {
            this.print_element()
        } else {
            console.error("print-controller#print", "must specify either printable element or path")
        }
    }

    print_url () {
        console.log("print-controller#print_url", this.element.dataset.path)
        const print_window = window.open(this.element.dataset.path, '')
        print_window.addEventListener('load', () => {
            console.log("print-controller#print_url", this.element.dataset.path, 'loaded')
            print_window.setTimeout(() => {
                print_window.print()
                console.log("print-controller#print_url", this.element.dataset.path, 'printed')
                print_window.close()
                console.log("print-controller#print_url", this.element.dataset.path, 'closed')
            }, 1e3)
        })
    }

    print_element () {
        console.log("print-controller#print_element", this.printableTarget)
        const print_window = window.open('', '')
        print_window.innerHTML = this.printableTarget.outerHTML
        print_window.print()
        print_window.close()
    }

}
