import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'

export default class extends Controller {
    connect () {
        console.log("application-controller#connect")
        this.setupModalDialog()
        this.ensureClassicSession()
        this.initBeagle()
    }

    initBeagle () {
        //Prevent Connections Dropdown closes on click
        $(".be-connections").on("click", function (e) {
            if (e.target.closest('.footer')) {
                $("#more_apps").collapse('toggle')
            }
            e.stopPropagation();
        })

        $('[data-toggle="tooltip"]').tooltip({
            trigger: 'hover'
        })
        $('[data-toggle="popover"]').popover()

        /*Bootstrap modal scroll top fix*/
        $('.modal')
        .on('show.bs.modal', function () {
            $("html").addClass('be-modal-open')
        })
        .on('hidden.bs.modal', function () {
            $("html").removeClass('be-modal-open')
        })
    }

    setupModalDialog() {
        const modal = $('#md-default')
        const defaultDialogContent = modal.html()

        modal.on('hidden.bs.modal ajax:error', () => modal.html(defaultDialogContent))

        // workaround for modal closing when dragging mouse outside modal-content
        // inspired by https://github.com/museumsvictoria/nodel/commit/ae0129fde30b0e453799ccbe0a429a88843481f5
        // and bootstrap.js:986-990
        .on('mousedown.dismiss.bs.modal', '.modal-content', function (e) {
            // do not prevent usage of close/cancel buttons
            if (e.target.closest('[data-dismiss]')) {
                return
            }
            // prevent next modal dismissal
            modal.one('mouseup.dismiss.bs.modal', function () {
                const modal_data = $(this).data('bs.modal')
                modal_data._ignoreBackdropClick = true
            })
        })

        // auto focus first form element
        .on('shown.bs.modal', function (e) {
            const first_field = e.target.querySelector("input:not([type='hidden']), select")
            if (first_field) {
                console.log('auto-focus', first_field)
                // select2 elements require special treatment
                if (first_field.dataset.select2Id) {
                    // the added delay is to accommodate the modal transition animation
                    // without it, the select2 popover will be misaligned
                    // setTimeout(() => $(first_field).select2('open'), 200)
                } else {
                    first_field.focus()
                }
            }
        })
    }

    ensureClassicSession() {
        if (this.data.get('mori') === '1') {
            jQuery.get("/mori/registry/UpdateAccount.do?beagle=true")
        }
    }
}
