import { Controller } from "@hotwired/stimulus"

const MIN_ZOOM = 10
const MAX_ZOOM = 400
const ZOOM_LEVELS = [
    MIN_ZOOM, 25, 50, 75, 100, 150, 200, 300, MAX_ZOOM
]

export default class extends Controller {
    static targets = [
        "container", "display", "input"
    ]

    connect () {
        console.log("zoom-controller#connect")
        this.level = +this.containerTarget.dataset.zoom || 100
    }

    zoomIn () {
        const level = ZOOM_LEVELS.find(e => e > this.level) || MAX_ZOOM
        this.setZoomTo(level)
    }

    zoomOut () {
        for (let i = ZOOM_LEVELS.length - 1; i >= 0; i--) {
            const level = ZOOM_LEVELS[i]
            console.log('zoomOut < ?', i, level)
            if (level < this.level) {
                this.setZoomTo(level)
                return
            }
        }
        this.setZoomTo(MIN_ZOOM)
    }

    reset () {
        this.setZoomTo(100)
    }

    setZoom (event) {
        const level = Math.min(Math.max(parseFloat(event.target.value), MIN_ZOOM), MAX_ZOOM)
        this.setZoomTo(level)
    }

    setZoomTo (level) {
        console.log("zoom-controller#setZoom", level)
        this.containerTarget.dataset.zoom = this.level = level
        const str = `${level}%`
        //this.containerTarget.style.fontSize = str
        this.containerTarget.style.transform = `scale(${level / 100})`
        if (this.hasDisplayTarget) {
            this.displayTarget.textContent = str
        }
        if (this.hasInputTarget) {
            this.inputTarget.value = str
        }
    }

    shortcuts (event) {
        if (event.ctrlKey || event.metaKey) {
            if (event.key === '-') {
                this.zoomOut()
                event.preventDefault()
            } else if (event.key === '=') {
                this.zoomIn()
                event.preventDefault()
            } else if (event.key === '0') {
                this.reset()
                event.preventDefault()
            }
        }
    }

    wheel (event) {
        if (event.ctrlKey || event.metaKey) {
            if (event.deltaY > 0) {
                this.zoomOut()
                event.preventDefault()
            } else if (event.deltaY < 0) {
                this.zoomIn()
                event.preventDefault()
            }
        }
    }
}
