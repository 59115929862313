import {Controller} from "@hotwired/stimulus"
import $ from 'jquery'

export default class extends Controller {
    static targets = [
        "content"
    ]

    connect () {
        console.log("clipboard#connect", this)
        this.contentElement = this.hasContentTarget ? this.contentTarget : this.element
        this.$contentElement = $(this.contentElement)
        this.content = this.contentElement.textContent.trim()
        this.$contentElement.popover({
            content: "Copied!",
            trigger: "manual",
            placement: "top"
        })
    }

    copy (event) {
        navigator.clipboard.writeText(this.content).then(() => this.copied())
        event.preventDefault()
    }

    copied () {
        console.log("clipboard#copied", this.content)
        this.$contentElement.effect("highlight", {color: "palegreen"}, 1500)
        this.$contentElement.popover('show')
        setTimeout(() => this.$contentElement.popover('hide'), 3000)
    }
}
