import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'

export default class extends Controller {
    map

    connect() {
        $("#new_m2_member_buffer_labels_controller_form").submit(function(){
            setTimeout(() =>{

                // Enable submit button or enable all
                // $.rails.enableFormElements($($.rails.formSubmitSelector))
                $('#labels-submit-button').removeAttr('disabled');

            }, 10000)
        })
    }
}
