import { Controller } from "@hotwired/stimulus"

const DRAGGABLE_SELECTOR = '.draggable'
const DRAGGING_CLASS = 'dragging'
const DURATION_THRESHOLD = 300 // ms, dragging must last at least this long
const PASSIVE_SCROLL = { passive: true }

export default class extends Controller {
    connect () {
        let element, frame, scale
        let startTime
        let firstX, firstY, lastX, lastY
        let initialTransform

        this.startDrag = event => {
            // only process left clicks (button = 0)
            if (event.button) return
            element = event.target.closest(DRAGGABLE_SELECTOR)
            if (element) {
                if (document.activeElement && element !== document.activeElement) {
                    if (element.contains(document.activeElement)) {
                        // abort drag if a descendant has focus
                        return
                    } else {
                        // blur if an unrelated element has focus
                        document.activeElement.blur()
                    }
                }
                startTime = Date.now()

                initialTransform = element.style.transform || ''

                const bounds = element.parentElement.getBoundingClientRect()
                scale = element.parentElement.offsetWidth / Math.round(bounds.width)
                firstX = lastX = event.clientX
                firstY = lastY = event.clientY
                console.log("start-drag", firstX, firstY, scale)

                document.addEventListener('pointermove', move, PASSIVE_SCROLL)
                document.addEventListener('pointerup', stopDrag, PASSIVE_SCROLL)
                document.addEventListener('pointercancel', stopDrag, PASSIVE_SCROLL)
                element.classList.add(DRAGGING_CLASS)

                event.preventDefault()
            }
        }

        function move (event) {
            lastX = event.clientX
            lastY = event.clientY
            frame = frame || requestAnimationFrame(render)
        }

        function render () {
            const deltaX = (lastX - firstX) * scale
            const deltaY = (lastY - firstY) * scale
            element.style.transform = `translate3d(${deltaX}px,${deltaY}px,0) ${initialTransform}`
            frame = null
        }

        function stopDrag (event) {
            document.removeEventListener('pointermove', move)
            document.removeEventListener('pointerup', stopDrag)
            document.removeEventListener('pointercancel', stopDrag)

            if (frame) {
                cancelAnimationFrame(frame)
                frame = null
            }

            element.style.transform = initialTransform
            element.classList.remove(DRAGGING_CLASS)

            // ignore very short events
            const duration = Date.now() - startTime
            if (duration < DURATION_THRESHOLD) return

            // perform move
            const x = element.offsetLeft + (event.clientX - firstX) * scale
            const y = element.offsetTop + (event.clientY - firstY) * scale
            element.style.left = `${x}px`
            element.style.top = `${y}px`

            const dragStopEvent = new CustomEvent('dragstop', {
                detail: {x, y}
            })
            element.dispatchEvent(dragStopEvent)
        }

        document.addEventListener('pointerdown', this.startDrag)
    }

    disconnect () {
        document.removeEventListener('pointerdown', this.startDrag)
    }
}
