import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "subtotal", "surcharge", "row", "total"
    ]

    connect () {
        this.percentage_charge = parseFloat(this.element.dataset.percentageCharge)
        this.transaction_fee = parseFloat(this.element.dataset.transactionFee)
        this.include_surcharge = this.element.dataset.includeSurcharge === 'true'
        this.platform_fee = parseFloat(this.element.dataset.platformFee)
        this.currency_code = this.element.dataset.currencyCode

        this.update()
    }

    update () {
        const subtotal = parseFloat(this.subtotalTarget.value) + this.platform_fee
        const surcharge = this._calculateSurcharge(subtotal)
        const total = this.include_surcharge ? subtotal + surcharge : subtotal

        this.surchargeTarget.textContent = this._renderCurrency(surcharge)
        this.totalTarget.textContent = this._renderCurrency(total)

        if (this.hasRowTarget) {
            this.rowTarget.classList.toggle('d-none', !this.include_surcharge)
        }
    }

    toggle (event) {
        this.include_surcharge = event.target.checked
        this.update()
    }

    _calculateSurcharge (total) {
        return Math.round(
            (this.percentage_charge * total + this.transaction_fee) * 100 / (1 - this.percentage_charge)
        ) / 100
    }

    _renderCurrency (amount) {
        return amount.toLocaleString([], {style: 'currency', currency: this.currency_code})
    }
}
