import {Controller} from "@hotwired/stimulus"
import { CountUp } from 'countup.js'

export default class extends Controller {
    connect() {
        console.log("count-up#connect", this.element)

        const {
            prefix = '',
            suffix = '',
            start = 0,
            end = 0,
            decimals = 0,
            duration = 2.5,
            delimiter = ',',
            separator = '.'
        } = this.element.dataset

        if (+end) {
            this.count = new CountUp(this.element, end, {
                startVal: start,
                decimalPlaces: decimals,
                duration,
                prefix,
                suffix,
                separator: delimiter,
                decimal: separator
            })

            this.count.start()
        }
    }
}
