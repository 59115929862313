import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'

export default class extends Controller {

    /*
    Usage:
    	set data-controller='conditional-forms' on the root form element
    	optionally set data-interstitial='<element selector>' to show when the form is submitted

    	set data-target='conditional-forms.conditional' on conditional sections of the form
    	set data-action='conditional-forms#update' on form elements which change conditions of the form

    	set data-visibility='<condition>' on sections which should only be visible when condition is true
    	set data-interlock='<condition>' on elements which should be deactivated when condition is true

    	<condition> is in the form <property>[.<data_attr>]=<value>[,<value>...], condition is true if <property> is one of <value>
     */

    static targets = [
        "conditional"
    ]

    visibility = {}
    interlock = {}
    disabled = {}

    connect() {
        console.log("conditional-forms#connect")

        this._createGroup("visibility", this.conditionalTargets)
        this._createGroup("interlock", this.conditionalTargets)
        this._createGroup("disabled", this.conditionalTargets)

        if (this.element.dataset.interstitial) {
            $(this.element).submit(this.showInterstitial.bind(this))
        }
    }

    update(event) {
        this._updateElement(event.currentTarget)
    }

    showInterstitial() {
        $(this.element).hide()
        $(this.element.dataset.interstitial).show()
        $(window).one('blur', e => $(this.element).closest('.modal').modal('hide'))
    }

    // define action to take when visibility condition for an element is met
    _visibilityAction(element, show) {
        console.log("conditional-forms#update visibility", show, element)
        $(element)[show ? "slideDown" : "slideUp"]("fast")
    }

    // define action to take when interlock condition for an element is met
    _interlockAction(element, disable) {
        console.log("conditional-forms#update interlock", disable, element)
        if (disable && element.checked) {
            if (element.type === "radio") {
                // find the first alternative radio option and select it
                const alternative = this.element.querySelector(`input[type=radio][name='${element.name}']:not([value='${element.value}'])`)
                alternative.checked = true
            } else {
                element.checked = false
                // element.dispatchEvent(new Event('change'))
                this._updateElement(element)
            }
        }
    }

    // define action to take when disabled condition for an element is met
    _disabledAction(element, disable) {
        console.log("conditional-forms#update disabled", disable, element)
        element.disabled = disable
    }

    _createGroup(group, elements) {
        for (const element of elements) {
            if (group in element.dataset) {
                let [name_data, conditions] = element.dataset[group].split('=', 2)
                conditions = conditions.split(',')
                let [name, data_attr] = name_data.split('.', 2)
                this[group][name] = this[group][name] || []
                this[group][name].push({element, conditions, data_attr})
            }
        }
    }

    _updateElement(element) {
        const group = element.name.match(/\[([^\]]+)\]$/)[1] || element.name
        const value = String(element.type === "checkbox" ? element.checked : element.value)
        const { dataset } = element.selectedOptions ?
            element.selectedOptions[0] : element

        console.log("conditional-forms#update", group, value, dataset)

        this._updateGroup(this.interlock[group], value, dataset, this._interlockAction.bind(this))
        this._updateGroup(this.visibility[group], value, dataset, this._visibilityAction.bind(this))
        this._updateGroup(this.disabled[group], value, dataset, this._disabledAction.bind(this))
    }

    _updateGroup(group, value, dataset, action) {
        if (group) {
            for (const section of group) {
                const resolved_value = section.data_attr ?
                    dataset[section.data_attr] : value
                const condition = section.conditions.includes(resolved_value)
                action(section.element, condition)
            }
        }
    }
}
