import {Controller} from "@hotwired/stimulus"
import $ from 'jquery'

export default class extends Controller {

    connect() {
        console.log("FOOOOOOOOO")
    }

    changed() {
        console.log("select list changed")
        $('#member-search-form').submit()
    }
}
