import { Controller } from "@hotwired/stimulus"
import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
// import bootstrapPlugin from '@fullcalendar/bootstrap'

import { Theme } from '@fullcalendar/core/internal'
class GroupableTheme extends Theme {}
Object.assign(GroupableTheme.prototype, {
    classes: {
        root: 'fc-theme-standard',
        // table: 'table-bordered',
        // tableCellShaded: 'table-active',
        tableCellShaded: 'fc-cell-shaded',
        buttonGroup: 'btn-group',
        button: 'btn btn-secondary',
        buttonActive: 'active',
        popover: 'popover',
        popoverHeader: 'popover-header',
        popoverContent: 'popover-body',
    },
    baseIconClass: 'icon mdi',
    iconClasses: {
        close: 'mdi-close',
        prev: 'mdi-chevron-left',
        next: 'mdi-chevron-right',
        prevYear: 'mdi-arrow-left',
        nextYear: 'mdi-arrow-right',
    },
    rtlIconClasses: {
        prev: 'mdi-chevron-right',
        next: 'mdi-chevron-left',
        prevYear: 'mdi-arrow-right',
        nextYear: 'mdi-arrow-left',
    }
})

import { createPlugin } from '@fullcalendar/core'
const GroupableThemePlugin = createPlugin({
    name: 'groupable',
    themeClasses: {
        groupable: GroupableTheme
    }
})

export default class extends Controller {
    calendar

    connect() {
        const options = this._buildOptions()
        this.calendar = new Calendar(this.element, options)
        this.calendar.render()
        console.log("calendar#connect", options, this.calendar)
    }

    disconnect() {
        console.log("calendar#disconnect")
        this.calendar.destroy()
    }

    _buildOptions() {
        // defaultView values: basicDay,basicWeek,listDay,listWeek,listMonth,listYear,agendaDay,agendaWeek,month
        // renamed: month:dayGridMonth basicDay,basicWeek,agendaWeek,agendaDay
        const { url, defaultView, contentHeight } = this.element.dataset
        const height = this._parseHeight(this.element.dataset.height)
        const eventLimit = this.element.dataset.eventLimit ?
            JSON.parse(this.element.dataset.eventLimit) : false
        const days = parseInt(this.element.dataset.days, 10)
        return {
            // plugins: [dayGridPlugin, listPlugin],
            // plugins: [dayGridPlugin, listPlugin, bootstrapPlugin],
            plugins: [dayGridPlugin, listPlugin, GroupableThemePlugin],
            themeSystem: 'groupable',
            // bootstrapFontAwesome: {
            //     prev: ' mdi mdi-chevron-left',
            //     next: ' mdi mdi-chevron-right',
            // },
            height,
            contentHeight,
            initialView: defaultView,
            dayMaxEventRows: eventLimit,
            headerToolbar: {
                left: 'prev,next',
                center: 'title',
                right: 'today,list,dayGridMonth'
            },
            views: {
                list: {
                    duration: { days },
                    listDaySideFormat: {
                        weekday: 'long'
                    }
                }
            },
            events: url,
            // timezone: false, TODO
            eventDisplay: 'block',
            eventDidMount: ({ event, el: element, view }) => {
                if (view.type === 'listYear') {
                    // TODO update
                    const title = element.find(".fc-list-item-title")
                    title.append("<br />" + event.description)
                }
                if (!event.title.indexOf("Cancel")) {
                    // TODO update
                    const title = element.find(".fc-list-item-title")
                    title.class("text-danger")
                }
            }
        }
    }

    _parseHeight (str) {
        const int = parseInt(str, 10)
        return int.toString() === str ? int : str
    }
}
