import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'

export default class extends Controller {
    static targets = ["member", "local"]

    member_selector

    connect() {
        console.log("select-member-local#connect")

        this.member_selector = $(this.memberTarget)
        this.member_selector.on('select2:select', this.update.bind(this))
    }

    disconnect() {
        console.log("select-member-local#disconnect")
        this.member_selector.off('select2:select')
    }

    update (e) {
        const option = e.params.data
        console.log("select-member-local#update", option)

        if (option && option.meta && option.meta.locals) {
            const {locals} = option.meta
            const select = this.localTarget

            // clear all but the placeholder
            select.options.length = 1

            for (const local of locals) {
                select.options.add(new Option(local.text, local.id, local.selected, local.selected))
            }
        }
    }
}
