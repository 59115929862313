import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "country", "state"
    ]

    states_by_country_id

    connect () {
        this.states_by_country_id = JSON.parse(this.stateTarget.dataset.states)
        this.update()
    }

    update () {
        // store current selection
        const current_value = this.stateTarget.value

        // clear select options, except the first (blank)
        this.stateTarget.options.length = 1

        const states = this.states_by_country_id[this.countryTarget.value]
        if (!states) return

        // populate new options
        for (const state of states) {
            this.stateTarget.options.add(new Option(state.name || state[1], state.id || state[0]))
        }

        this.stateTarget.value = current_value
    }
}
