import {Controller} from "@hotwired/stimulus"
import $ from 'jquery'

const INPUT_SELECTOR = 'input, select'

export default class extends Controller {
    connect () {
        console.log("expanding-form#connect", this)

        const form_body = $(this.element)
        const rows = form_body.children('.form-row')

        let next_index = rows.length
        let last_row = rows.last()

        function addRow () {
            // clone the template
            const new_row = last_row.clone()
            // update name and id of each input
            new_row.find(INPUT_SELECTOR).each(function () {
                this.id = this.id.replace(/\d+/, next_index)
                this.name = this.name.replace(/\d+/, next_index)
            })
            next_index++
            // adjust event handlers
            last_row.find(INPUT_SELECTOR).off('focus')
            new_row.find(INPUT_SELECTOR).focus(addRow)
            last_row = new_row
            // insert the new row into the document
            form_body.append(new_row)
        }

        last_row.find(INPUT_SELECTOR).focus(addRow)
    }
}
