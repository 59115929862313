import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs'

export default class extends Controller {
    static targets = [
        "selector", "container"
    ]

    connect () {
        console.log('ajax-form-field#connect')
        if (this.hasSelectorTarget) {
            this.update({ target: this.selectorTarget })
        }
        this.path = this.data.get('path')
    }

    update (event) {
        console.log('ajax-form-field#update')
        fetch(this.path, {
            method: 'POST',
            headers: {
                'X-CSRF-Token': Rails.csrfToken()
            },
            body: new FormData(event.target.closest('form'))
        }).
            then(response => response.text()).
            then(text => this.containerTarget.innerHTML = text)
    }
}
