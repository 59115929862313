import {Controller} from "@hotwired/stimulus"
import Rails from '@rails/ujs'
import $ from 'jquery'

const DEFAULT_TIMEOUT = 10

export default class extends Controller {
    connect () {
        console.log("autosave-form#connect", this)
        this.timeout = +this.element.dataset.timeout || DEFAULT_TIMEOUT
        $(this.element).change(() => this._queueSave())
    }

    _queueSave () {
        console.log("autosave-form#_queueSave")
        clearTimeout(this.timer)
        this.timer = setTimeout(() => this._save(), this.timeout)
    }

    _save () {
        console.log("autosave-form#_save")
        Rails.fire(this.element, 'submit')
    }

    disconnect () {
        console.log("autosave-form#disconnect", this)
        $(this.element).off('change')
    }
}
