import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["input", "filename"]

    update () {
        console.log('file-upload#update')
        this.filenameTarget.textContent = this._getFileName()
    }

    _getFileName () {
        return Array.from(this.inputTarget.files).map(file => file.name).join()
    }
}
