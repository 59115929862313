import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js'

export default class extends Controller {
    static targets = [
        "chart", "row", "value"
    ]

    connect () {
        // allow usage of chart controller on the chart element itself without specifying target
        this.chartElement = this.hasChartTarget ? this.chartTarget : this.element

        this.chart = new Chart(this.chartElement.getContext('2d'), this._config())
    }

    disconnect () {
        this.chart.destroy()
    }

    update (event) {
        const index = +event.target.dataset.index
        const { data } = this.config.data.datasets[0]
        const value = parseFloat(event.target.value) || 0
        // NOTE: intentional usage of coercive comparison - data may be string encoded
        // noinspection EqualityComparisonWithCoercionJS
        if (index in data && data[index] != value) {
            data[index] = value
            this.chart.update()
            // update legend values if present
            const row = this.rowTargets[index]
            if (row) {
                row.classList.toggle('d-none', !value)
            }
            const row_value = this.valueTargets[index]
            if (row_value) {
                row_value.textContent = this._formatValue(value)
            }
        }
    }

    _formatValue (value) {
        switch (this.datatype) {
            case 'currency':
                return value.toLocaleString([], {style: 'currency', currency: this.currencyCode})
            case 'percentage':
                return (value / 100).toLocaleString([], {style: 'percent'})
        }
        return value.toString()
    }

    _config () {
        const config = this.config = JSON.parse(this.chartElement.dataset.chart)
        this.datatype = this.config.options && this.config.options.datatype
        this.currencyCode = this.config.options && this.config.options.currencyCode
        if (config && config.options && config.options.href) {
            // UJS style SJR
            config.options.onClick = (event, elements) => {
                const href = this._href(elements)
                if (href) {
                    if (config.options.remote) {
                        this._sjr(href)
                    } else {
                        Turbolinks.visit(href)
                    }
                }
            }
            // change when hovering over clickable elements
            config.options.onHover = (event, elements) => {
                this.chartElement.style.cursor = this._href(elements) ? 'pointer' : ''
            }
        }
        if (this.datatype) {
            config.options.tooltips = config.options.tooltips || {}
            config.options.tooltips.callbacks = {
                title: (tooltips, data) => tooltips.map(({index}) => data.labels[index]).join(),
                label: (tooltip, data) => {
                    const value = data.datasets[tooltip.datasetIndex].data[tooltip.index]
                    return this._formatValue(+value)
                }
            }
            if (config.type === 'bar') {
                config.options.scales = config.options.scales || {}
                config.options.scales.yAxes = [{
                    ticks: {
                        beginAtZero: true,
                        callback: value => this._formatValue(value)
                    }
                }]
            }
        }
        return config
    }

    _href (elements) {
        if (elements.length) {
            const target = elements[0]
            return this.config.data.datasets[target._datasetIndex].href[target._index]
        }
    }

    _sjr (url) {
        fetch(url, {
            headers: {
                'Accept': 'application/javascript',
                'X-CSRF-Token': this._csrf_token(),
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).
        then(response => response.text()).
        then(text => {
            const script = document.createElement('script')
            script.text = text
            document.head.appendChild(script).parentNode.removeChild(script)
        })
    }

    _csrf_token () {
        return document.querySelector('meta[name=csrf-token]').content
    }
}
