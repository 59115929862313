import { Controller } from "@hotwired/stimulus"

const PT_PER_IN = 72
const PX_PER_IN = 96
const PT_TO_PX = PX_PER_IN / PT_PER_IN
const PX_TO_PT = PT_PER_IN / PX_PER_IN
const px2pt = px => Math.round(px * PX_TO_PT)

const LINE_HEIGHT = 1.08

const
    TYPE_TEXT = 'text',
    TYPE_IMAGE = 'image',
    TYPE_BARCODE = 'barcode',
    TYPE_QRCODE = 'qrcode'

export default class extends Controller {
    connect () {
        // store a ref to this controller on the DOM
        this.element.controller = this
        console.log('print-template-field#connect', this.element, this.identifier, this)

        this.element.classList.add('print-template-field', 'draggable')
        this.element.dataset.action = [
            'focus->print-template-editor#setFocus',
            'dragstop->print-template-field#updatePosition',
            'resize->print-template-editor#updateSize',
            'reposition->print-template-editor#updatePosition',
        ].join(' ')
        this.element.tabIndex = 0

        this.content = this.element.querySelector('.content')
        this.content.dataset.action = 'mouseup->print-template-field#updateSize print-template-editor#updateContent'
        this.content.tabIndex = -1
        this.element.ondblclick = e => this.content.focus()

        // set initial properties from hidden field values
        this._connectFields()
    }

    updatePosition (event) {
        const x = px2pt(event.detail.x)
        const y = px2pt(event.detail.y)
        const xChanged = this.set('x', x)
        const yChanged = this.set('y', y)
        if (xChanged || yChanged) {
            this.element.dispatchEvent(new Event('reposition'))
        }
    }

    updateSize (event) {
        // no need to update if unit did not change
        const unit = this.content.style.width.slice(-2)
        if (unit === 'pt') return

        let width = px2pt(this.content.clientWidth)
        let height = px2pt(this.content.clientHeight)
        if (this.type === TYPE_QRCODE) {
            width = height = Math.min(width, height)
        }
        const widthChanged = this.set('width', width)
        const heightChanged = this.set('height', height)
        if (widthChanged || heightChanged) {
            this.element.dispatchEvent(new Event('resize'))
        }
    }

    set (name, value) {
        const field = this._fields[name]
        if (field.value !== value.toString()) {
            console.log('print-template-editor#set', name, 'value changed', field.value, value)
            field.value = value
            this._values[name] = value
            this._update(name, value)
            return true
        } else {
            return false
        }
    }

    setAll (values) {
        for (const name in values) {
            this.set(name, values[name])
        }
    }

    get (name) {
        return this._values[name]
    }

    getAll () {
        return this._values
    }

    _update (name, value) {
        // universal properties
        switch (name) {
            case 'id':
            case 'image':
                return
            case 'type':
                if (this.type) {
                    console.error('print-template-field', 'field type change', this.type, value)
                    this.element.classList.remove(this.type)
                }
                this.type = value
                this.element.classList.add(value)
                return
            case 'x':
                this.element.style.left = `${value}pt`
                return
            case 'y':
                this.element.style.top = `${value}pt`
                return
            case 'z':
                this.element.style.zIndex = value
                return
            case 'width':
                this.content.style.width = `${value}pt`
                return
            case 'height':
                this.content.style.height = `${value}pt`
                return
            case 'rotation':
                this.element.style.transform = `rotate(${value}deg)`
                return
            case 'opacity':
                this.content.style.opacity = value / 100
                return
        }
        const type = this.get('type')
        // text, barcode, and qrcode properties
        if (type === TYPE_IMAGE) {
            return
        }
        switch (name) {
            case 'text_transform':
                this.element.style.textTransform = value
                return
        }
        // text and barcode properties
        if (![TYPE_TEXT, TYPE_BARCODE].includes(type)) {
            return
        }
        switch (name) {
            case 'font_size':
                // ensure field height is at least line height
                const minHeight = Math.ceil(value * LINE_HEIGHT)
                const currentHeight = this.get('height')
                if (currentHeight < minHeight) {
                    this.set('height', minHeight)
                    this.element.dispatchEvent(new Event('resize'))
                }
                this.element.style.fontSize = `${value}pt`
                return
            case 'text_align':
                this.element.style.textAlign = value
                return
        }
        // text only properties
        if (type !== TYPE_TEXT) {
            return
        }
        switch (name) {
            case 'font_family':
                this.element.style.fontFamily = value
                return
            case 'font_style':
                this.element.style.fontWeight = value.includes('bold') ? 'bold' : ''
                this.element.style.fontStyle = value.includes('italic') ? 'italic' : ''
                this.element.style.textDecoration = value.includes('underline') ? 'underline' : ''
                return
            case 'color':
                this.element.style.color = value
                return
        }
        console.log('print-template-field', 'unknown property', name, value)
    }

    _connectFields () {
        this._fields = {}
        this._values = {}
        for (const field of this.element.querySelectorAll("input[type='hidden']")) {
            const name = field.name.match(/\[([^\]]+)]$/)[1]
            this._fields[name] = field
            this._values[name] = field.value
            this._update(name, field.value)
        }
    }
}
