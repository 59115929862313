import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'

export default class extends Controller {

    connect () {
        this.refresh()
    }

    refresh () {
        console.log('show-members-controller#refresh')

        const memberId = this.data.get("memberId")
        const partyId = this.data.get("partyId")

        $("#member-content").load(`/members/${memberId}/member`)
        $("#member-widgets").load(`/members/${memberId}/member_widgets`)
        $("#contact-content, #mobile-contact-content").load(`/party_assets/${partyId}/contact_info_panel`);
        $("#mobile-memberships-content").load(`/members/${memberId}/memberships`);
        $("#memberships-content").load(`/members/${memberId}/memberships_table`, () => {
            $("#contact-preferences, #mobile-contact-preferences").load(`/party_assets/${partyId}/contact_preferences_panel`);
            $("#bio-content").load(`/party_assets/${partyId}/bio_info_panel`);
            $("#offices_held_content").load(`/members/${memberId}/offices_held`);
            $("#member-data-content").load(`/members/${memberId}/member_data`);
            $("#dues-content").load(`/members/${memberId}/dues`);
            $("#local-member-types-content").load(`/members/${memberId}/local_member_types`);
            $("#member_awards_content").load(`/members/${memberId}/member_awards`);
            $("#educations_content").load(`/members/${memberId}/educations`);
            $("#timeline-content").load(`/members/${memberId}/timeline`);
            $("#notes-content").load(`/members/${memberId}/notes`);
            $("#fundraising_notes_card").load(`/members/${memberId}/fundraising_notes`);
            $("#user-account-content").load(`/members/${memberId}/user_account`);
            $("#certifications-content").load(`/members/${memberId}/certifications`);
            $("#military_services_content").load(`/members/${memberId}/military_services`);
            $("#emails-content").load(`/members/${memberId}/emails`);
            $("#party_subscriptions_content").load(`/members/${memberId}/party_subscriptions`);
        });
    }

}
