import {Controller} from "@hotwired/stimulus"
import $ from 'jquery'

export default class extends Controller {
    static targets = ["applicatorId", "eventId", "localId", "groupId", "date"]

    applicatorIdChanged() {
        console.log('member-life-cycle-events#applicatorIdChanged')
        if (this.applicatorIdTarget.value) {
            this.loadSelector('#local_selector_place_holder', this.data.get('local-selector-path'))
        } else {
            $("#local_selector_place_holder").html('')
        }
        this.dateTarget.value = ''
        this.dateChanged()
    }

    applicatorIdLoaded() {
        if (this.applicatorIdTarget.value && !this.hasLocalIdTarget) {
            this.applicatorIdChanged()
        }
    }

    dateChanged() {
        $('#return_period_selector_holder').html('')
        if (this.dateTarget.value && this.applicatorIdTarget.value) {
            this.loadSelector('#return_period_selector_holder', this.data.get('return-period-selector-path'))
        }
    }

    loadSelector(elementId, path) {
        fetch(this.buildUri(path))
            .then(response => response.text())
            .then(data => {
                this.replaceFormGroup(elementId, data)
                $(`${elementId} select`).focus()
            })
    }

    localChanged() {
        console.log("localChanged")
        // this.dateTarget.value = ''
        this.dateChanged()
    }


    replaceFormGroup(elementId, data) {
        $(elementId).html($('div.form-group', $(data)))
    }

    buildUri(path) {
        return `${path}?${$('#new_forms_member_life_cycle_event_create_form').serialize()}`
    }

}
