import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs'

const DEFAULT_FILENAME = 'file'

export default class extends Controller {
    submit (event) {
        const path = this.data.get('path')
        console.log('download-controller#submit', path)
        fetch(path, {
            method: 'POST',
            headers: {
                'X-CSRF-Token': Rails.csrfToken()
            },
            body: new FormData(this.element)
        }).
            then(response => this._checkResponse(response)).
            then(response => this._setFilename(response)).
            then(response => response.blob()).
            then(blob => this._saveBlob(blob)).
            catch(reason => this._handleError(reason))
    }

    _checkResponse (response) {
        if (response.ok) {
            return response
        }
        throw new Error('Internal server error')
    }

    _setFilename (response) {
        const disposition = response.headers.get('content-disposition')
        this.filename = disposition.match(/filename="([^"]+)"/)[1]
        return response
    }

    _saveBlob (blob) {
        console.log('download-controller#_saveBlob', blob)
        const uri = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = uri
        a.download = this.filename || DEFAULT_FILENAME
        a.target = '_blank'
        document.body.appendChild(a)
        a.click()
        a.remove()
        URL.revokeObjectURL(uri)
    }

    _handleError (reason) {
        console.log('download-controller#_handleError', reason)
        alert(reason)
    }
}
