import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "dependent"
    ]

    update (event) {
        event.currentTarget.checked ? this.enable() : this.disable()
    }

    enable () {
        this.toggle(false)
    }

    disable () {
        this.toggle(true)
        this.dependentTarget.checked = false
    }

    toggle (disabled) {
        this.dependentTarget.disabled = disabled
        this.dependentTarget.classList.toggle('disabled', disabled)
        this.dependentTarget.parentElement.classList.toggle('disabled', disabled)
    }
}
