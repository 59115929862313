import {Controller} from "@hotwired/stimulus"

const BUFFER_SIZE = 512
const MAX_PREVIEW_ROWS = 2
const IGNORE_HEADER = 'ignore'
const HEADER_STORAGE_KEY = "lockbox_default_headers"

export default class extends Controller {
    static targets = ["input", "preview", "filename", "submit", "hint", "select"]

    connect () {
        console.log('csv-preview#connect')
        this.tableColumnHeader = this.previewTarget.querySelector('th').cloneNode(true)

        this.headerNames = {}
        for (const option of this.selectTarget.options) {
            this.headerNames[option.value] = option.text
        }
        this.headerOptions = Object.values(this.headerNames)

        this.defaultHeaders = JSON.parse(localStorage[HEADER_STORAGE_KEY] || this.element.dataset.defaultHeaders)
        this.requiredHeaders = JSON.parse(this.element.dataset.requiredHeaders)

        this.element.addEventListener('submit', this.storeDefaultHeaders.bind(this))
    }

    render () {
        console.log('csv-preview#render')

        const input = this.inputTarget
        const preview = this.previewTarget
        const filename = this.filenameTarget

        preview.classList.remove('show')
        filename.textContent = ''

        if (input.files && input.files.length === 1) {
            const file = input.files[0]
            filename.textContent = file.name

            const reader = new FileReader

            reader.onload = () => {
                console.log('csv-preview#render', 'loaded', reader.result)

                const data = this.readCSV(reader.result)
                // show at most MAX_PREVIEW_ROWS rows
                data.length = Math.min(data.length, MAX_PREVIEW_ROWS)
                this.renderPreview(data)
                this.renderValidations()
            }

            console.log('csv-preview#render', 'loading', file)
            // loading only the first BUFFER_SIZE bytes to inspect the CSV header
            reader.readAsText(file.slice(0, BUFFER_SIZE))
        }
    }

    getDefaultHeaders (length) {
        const headers = [...this.defaultHeaders]
        if (length) headers.length = length
        return headers
    }

    getCurrentHeaders () {
        return [...this.selectTargets].map(s => s.value)
    }

    storeDefaultHeaders () {
        localStorage[HEADER_STORAGE_KEY] = JSON.stringify(this.defaultHeaders = this.getCurrentHeaders())
    }

    readCSV (str) {
        const lines = str.split(/\r?\n|\r/).filter(line => line)
        // heuristic for checking if the first line is a header
        // if there are any digits in the first line, we assume there is no header
        const header = /^\D+$/.test(lines[0])
        const table = lines.map(
            line => line.split(',')
            // line => line.match(/"(?:\\.|[^"])*"|'(?:\\.|[^'])*'|[^,]*/g)
        )
        if (header) {
            table.header = table.shift()
        }
        table.width = table.length ? table[0].length : 0
        return table
    }

    renderPreview (csv) {
        const preview = this.previewTarget
        csv.header = csv.header || this.getDefaultHeaders(csv.width)
        // render table header
        const headerRow = preview.querySelector('table > thead > tr')
        headerRow.innerHTML = ''
        for (const value of csv.header) {
            const cell = this.tableColumnHeader.cloneNode(true)
            headerRow.appendChild(cell)
            const select = cell.querySelector('select')
            select.value = value
            if (!select.value) {
                select.selectedIndex = Math.max(this.headerOptions.indexOf(value), 0)
            }
        }
        // render table body
        const body = preview.querySelector('table > tbody')
        body.innerHTML = ''
        for (const row of csv) {
            const tr = document.createElement('tr')
            body.appendChild(tr)
            for (const value of row) {
                const cell = document.createElement('td')
                tr.appendChild(cell)
                cell.textContent = value
            }
        }
        // noinspection BadExpressionStatementJS
        preview.clientWidth // reflow in order to trigger transition on .show
        preview.classList.remove('d-none')
        preview.classList.add('show')
    }

    validateHeaders () {
        const headers = this.getCurrentHeaders()
        const missing_headers = []
        for (const header of this.requiredHeaders) {
            if (!headers.includes(header)) {
                missing_headers.push(header)
            }
        }
        const duplicate_headers = [], alert_headers = []
        for (let i = 0; i < headers.length; i++) {
            const header = headers[i]
            if (!header) continue
            if (header === IGNORE_HEADER) {
                alert_headers.push(i)
            } else if (headers.indexOf(header) !== i) {
                duplicate_headers.push(i)
            }
        }
        const invalid = missing_headers.length + duplicate_headers.length
        return { missing_headers, duplicate_headers, alert_headers, invalid }
    }

    renderValidations () {
        const validations = this.validateHeaders()
        if (validations.invalid) {
            this.submitTarget.disabled = true
        } else {
            this.submitTarget.disabled = false
        }

        if (validations.missing_headers.length) {
            const headerNames = validations.missing_headers.map(id => this.headerNames[id])
            this.hintTarget.textContent = `Missing required headers ${headerNames.join(', ')}`
            this.hintTarget.classList.add('d-block')
        } else {
            this.hintTarget.textContent = ''
            this.hintTarget.classList.remove('d-block')
        }

        const selects = this.selectTargets
        // reset validation feedback
        for (const select of selects) {
            select.classList.remove('is-invalid', 'outline-warning')
        }
        for (const i of validations.duplicate_headers) {
            selects[i].classList.add('is-invalid')
        }
        for (const i of validations.alert_headers) {
            selects[i].classList.add('outline-warning')
        }
    }
}
