import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'

export default class extends Controller {

    /*
    Save and restore selected form fields using sessionStorage

    Currently supports radio, checkbox, select, and select2 field types

    Usage:
        set data-controller='persist-form' on the root form element
        set data-save-id='<id>' to identify the form group
        set data-persist to a space separated list of field names to save/restore

        optionally set data-action='persist-form#save' on other buttons/elements to trigger
        additional save; submit is bound automatically

        N.B. Field names exclude the Rails generated form name, so that fields can be transferred
        between different forms, so long as they share field names.

        ie. 'form_name_form[field_name]' becomes simply 'field_name'
     */

    connect() {
        const { persist, saveId } = this.element.dataset
        this.persist = persist.split(' ')
        this.saveId = saveId

        console.log("persist-form#connect", this.saveId, this.persist)
        $(this.element).submit(this.save.bind(this))

        // delay load until next tick to allow other controllers to connect
        setTimeout(this.load.bind(this), 0)
    }

    save() {
        const formState = JSON.parse(sessionStorage.getItem(this.saveId)) || {}
        this._getFormState(this.persist, formState)
        console.log("persist-form#save", formState)
        sessionStorage.setItem(this.saveId, JSON.stringify(formState))
    }

    load() {
        const formState = JSON.parse(sessionStorage.getItem(this.saveId))
        if (formState) {
            console.log("persist-form#load", formState)
            this._setFormState(formState)
            // sessionStorage.removeItem(this.saveId)
        }
    }

    _getFormState(properties, formState) {
        for (const prop of properties) {
            formState[prop] = this._getValue(prop)
        }
        return formState
    }

    _setFormState(formState) {
        for (const prop in formState) {
            this._setValue(prop, formState[prop])
        }
    }

    _getValue(name) {
        if (document.querySelector(`input[type=radio][name$='[${name}]']`)) {
            return this._getRadioValue(name)
        } else if (document.querySelector(`input[type=checkbox][name$='[${name}]']`)) {
            return this._getCheckboxValue(name)
        } else if (document.querySelector(`select[name$='[${name}]']`)) {
            return this._getSelectValue(name)
        } else if (document.querySelector(`select[name=${name}].select2-hidden-accessible`)) {
            return this._getSelect2Value(name)
        }
    }

    _setValue(name, data) {
        if (document.querySelector(`input[type=radio][name$='[${name}]']`)) {
            return this._setRadioValue(name, data)
        } else if (document.querySelector(`input[type=checkbox][name$='[${name}]']`)) {
            return this._setCheckboxValue(name, data)
        } else if (document.querySelector(`select[name$='[${name}]']`)) {
            return this._setSelectValue(name, data)
        } else if (document.querySelector(`select[name=${name}].select2-hidden-accessible`)) {
            return this._setSelect2Value(name, data)
        }
    }

    _getRadioValue(name) {
        return document.querySelector(`input[type=radio][name$='[${name}]']:checked`).value
    }

    _setRadioValue(name, value) {
        const element = document.querySelector(`input[type=radio][name$='[${name}]'][value='${value}']`)
        if (element) {
            element.checked = true
            element.dispatchEvent(new Event('change'))
        }
    }

    _getCheckboxValue(name) {
        return document.querySelector(`input[type=checkbox][name$='[${name}]']`).checked
    }

    _setCheckboxValue(name, value) {
        const element = document.querySelector(`input[type=checkbox][name$='[${name}]']`)
        element.checked = value
        element.dispatchEvent(new Event('change'))
    }

    _getSelectValue(name) {
        return document.querySelector(`select[name$='[${name}]']`).value
    }

    _setSelectValue(name, value) {
        const element = document.querySelector(`select[name$='[${name}]']`)
        element.value = value
        element.dispatchEvent(new Event('change'))
    }

    _getSelect2Value(name) {
        const opt = $(`select[name=${name}]`).select2('data')[0]
        return { id: opt.id, text: opt.text }
    }

    _setSelect2Value(name, data) {
        let select = $(`select[name=${name}]`)
        if (select.find("option[value='" + data.id + "']").length) {
            select.val(data.id).trigger('change')
        } else {
            select.append(new Option(data.text, data.id, true, true)).trigger('change')
        }
    }
}
