// Entry point for the build script in your package.json

// package stylesheets
import './stylesheets/application.scss'

// start framework code and apply patches
import './initializers'

// import global dependencies
import 'jquery-ui/dist/jquery-ui'
import 'bootstrap/dist/js/bootstrap.bundle'
import 'summernote/dist/summernote-bs4.js'
import 'bootstrap-datetime-picker/js/bootstrap-datetimepicker'
import 'Gritter/js/jquery.gritter'
import 'jquery-timepicker/jquery.timepicker'
import 'magnific-popup'

// load stimulus controllers
import "./controllers"

console.log("application loaded")
